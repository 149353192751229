import React, { Suspense, lazy } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Forget from './auth/Forget';
import Varify from './auth/Varify';
import ResetPassword from './auth/ResetPassword';
import ProtectRoute from './auth/ProtectRoute';
import GraphFeedback from "./components/GraphFeedback";
import './App.css';
import GraphAllQuestions from './components/GraphAllQuestions';
import EditProject from './components/EditProject/EditProject';
// import EditProject from './components/EditProject';
import Participant from './components/Participant';
// import 'bootstrap/dist/css/bootstrap.min.css';


const Home = lazy(() => import('./components/home'));
const Login = lazy(() => import('./auth/login'));
const Signup = lazy(() => import('./auth/signup'));
const Projects = lazy(() => import('./components/projects'));
const Openprojects = lazy(() => import('./components/openprojects'));
const Projectdetails = lazy(() => import('./components/projectdetails'));
const Support = lazy(() => import('./components/support'));
const Createnew = lazy(() => import('./components/createnew'));
const Bonuskit = lazy(() => import('./components/bonuskit'));
const Createnew2 = lazy(() => import('./components/createnew2'));
const Createquestion = lazy(() => import('./components/createquestion'));
const Elicitationsession = lazy(() => import('./components/elicitationsession'));
const Addresponse = lazy(() => import('./components/addresponse'));
const Analysis = lazy(() => import('./components/analysis'));
const GetAllQuestions = lazy(() => import('./components/AllQuestions'));
const ErrorPage = lazy(() => import('./auth/ErrorPage'));




function App() {
  
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path='/' element={<ProtectRoute Component={Home} />} />
          <Route path='/login' element={<Login />} />
          <Route path='/forget' element={<Forget />} />
          <Route path='/varify' element={<Varify />} />
          <Route path='/resetpassword' element={<ResetPassword />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/projects' element={<ProtectRoute Component={Projects} /> } />
          <Route path='/openprojects' element={<ProtectRoute Component={Openprojects}  />} />
          <Route path='/projectdetails' element={<ProtectRoute Component={Projectdetails}  />} />
          <Route path='/support' element={<ProtectRoute Component={Support} />} />
          <Route path='/createnew' element={<ProtectRoute Component={Createnew}  />} />
          <Route path='/bonuskit' element={<ProtectRoute Component={Bonuskit}  />} />
          <Route path='/createnew2' element={<ProtectRoute Component={Createnew2}   />} />
          <Route path='/createquestion' element={<ProtectRoute Component={Createquestion}  />} />
          <Route path='/allquestion' element={<ProtectRoute Component={GetAllQuestions} />}/>
          <Route path='/elicitationsession' element={<ProtectRoute Component={Elicitationsession}  />} />
          <Route path='/addresponse' element={<ProtectRoute Component={Addresponse}  />} />
          <Route path='/analysis' element={<ProtectRoute Component={Analysis}  />} />
          <Route path='/graphfeedback' element={<ProtectRoute Component={GraphFeedback}  />} />
          <Route path='/editproject' element={<ProtectRoute Component={EditProject}  />} />
          <Route path='/graphallquestions' element={<ProtectRoute Component={GraphAllQuestions}  />} />
          <Route path='/participant' element={<ProtectRoute Component={Participant}  />} />
          <Route path='*' element={<ProtectRoute Component={ErrorPage}  />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;

