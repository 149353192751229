import React from "react";
import { FaCircle } from "react-icons/fa";

const CustomTooltip = ({ active, payload, apidata }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div className="bg-white px-3 py-1 shadow tooltip-container">
        <div>{`Value: ${data.value}`}</div>
        <div className="username-container" >
          {apidata
            .filter((val) => val.key === data.name)
            .map((val, index) => (
              <div key={index}>
                <span style={{ fontSize: "10px"}}>
                  {val.usernames.map((username, idx) => (
                    <div key={idx}><FaCircle size={8} style={{ marginRight: "5px" }} />{` ${username}`}</div>
                  ))}
                </span>
              </div>
            ))}
        </div>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
