import React, { useState, useEffect } from 'react'
import logo from "./logo.png"
import { Link, useLocation } from 'react-router-dom';
export default function Header() {
    const token = localStorage.getItem("accessToken");
    const [view, setView] = useState(false);
    const [ShowHide, setShowHide] = useState("")
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [xicon, setXicon] = useState(window.innerWidth <= 768 ? 'd-block' : 'd-none');
    const location = useLocation();


    const handleSidebarView = () => {
        setView((prevView) => !prevView);
    };

    window.addEventListener("scroll", (event) => {
        let scroll = window.pageYOffset
        if (scroll > 100) {

            setShowHide("sticky-header")
        }
        else {
            setShowHide("")
        }

    });


    const handleResize = () => {
        const width = window.innerWidth;
        setWindowWidth(width);

        if (width <= 768) {
            setXicon('d-block');
        } else {
            setXicon('d-none');
        }
    };


    useEffect(() => {

        // Add event listener on component mount
        window.addEventListener("resize", handleResize);

    }, []);


    useEffect(() => {
        // Update the xicon state when the route changes
        if (windowWidth <= 768) {
            setXicon('d-block');
        }
    }, [location.pathname, windowWidth]);


    return (
        <>
            <header>
                {/*Start Navbar*/}
                <nav className={`navbar navbar-expand-lg navbar-inner fixed-top custom-nav sticky w-100 rounded-0 mb-0 ${ShowHide}`}>
                    <div className="menu-overlay" />
                    <div className="container-fluid">
                        {/* LOGO */}
                        <Link className="navbar-brand brand-logo mr-4 navwidth" to="/" >
                            <img src={logo} className="img-fluid logo-light lightwid" alt="" />
                            <img
                                src={logo}
                                className="img-fluid logo-dark darkwid"
                                alt=""

                            />
                        </Link>
                        <div
                            className={`navbar-collapse collapse justify-content-end  ${view ? 'show' : ''}`}

                        >
                            <ul className="navbar-nav navbar-center">
                                <li className={`nav-item ${xicon}`}>
                                    {windowWidth <= 768 && (
                                        <span onClick={handleSidebarView} className="nav-link headercolor" style={{ padding: '6px 22px', fontSize: '15px' }}>
                                            x
                                        </span>
                                    )}
                                </li>
                                <li className="nav-item">
                                    <div style={{color:'#1e1e1e', cursor:'pointer'}} className="nav-link headercolor fw-bold mx-3">
                                        Product
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div
                                       style={{color:'#1e1e1e', cursor:'pointer'}}
                                        className="nav-link headercolor transition fw-bold mx-3"
                                    >
                                        Templates
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div
                                       style={{color:'#1e1e1e', cursor:'pointer'}}
                                        className="nav-link headercolor transition fw-bold mx-3"
                                    >
                                        Pricing
                                    </div>
                                </li>


                                <li className="nav-item">
                                    <div
                                        style={{color:'#1e1e1e', cursor:'pointer'}}
                                        className="nav-link headercolor transition fw-bold"
                                    >
                                        Blog

                                    </div>
                                </li>

                                <li className="nav-item">
                                {token? <div onClick={()=>localStorage.removeItem("accessToken")} className="fw-bold nav-link headercolor transition" style={{color:"#1e1e1e", cursor:'pointer'}}>Log Out</div>:
                                  <div to="/login" className="fw-bold nav-link headercolor transition" style={{color:"#1e1e1e"}}>Sign in</div>  }
                                </li>

                            </ul>
                        </div>
                        {/* <div className="contact_btn">
                            <button

                                className="btn theme-btn mx-2 hedrbtn justify-content-center align-items-center hbg"
                            >
                                Request a demo
                            </button>

                            <button

                                className="btn theme-btn hedrbtn  justify-content-center align-items-center hbg"
                            >
                                Start for free
                            </button>
                            <button
                                onClick={handleSidebarView}
                                className="navbar-toggler ml-2 p-0 mb-0"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarCollapse"
                                aria-controls="navbarCollapse"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <i className="mdi mdi-menu" />
                            </button>
                        </div> */}
                    </div>
                </nav>
            </header>



        </>


    )
}



