import { useEffect, useState } from "react";
// import Header from "../layout/header";
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Rings } from "react-loader-spinner";
// import { FaEdit } from "react-icons/fa";
// import { MdDeleteForever } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import BaseUrl from "../../auth/BaseUrl";

const GetAllQuestion = () => {
  // const navigate = useNavigate();
  // console.log(navigate)
  const token = localStorage.getItem("accessToken");
  const [loader, setloader] = useState(false);
  const [questionValue, setQuestionValue] = useState({});
  // const projectid = JSON.parse(localStorage.getItem("dataId"));
  const projectid = localStorage.getItem("projectid");
  console.log(projectid, "projectid");
  // const location = useLocation();
  // const projectid = location?.state;

  const [questionsdata, setQuestionsdata] = useState([]);
  const [editedValue, setEditedValue] = useState("");
  const [Id , setId] = useState("");
  console.log(editedValue);

  const [UpdateQues,]=useState([])
  console.log(UpdateQues);

  const getAlldata = async () => {
    var config = {
      method: "get",
      url: `${BaseUrl.baseurl}question/get-question/?project_id=${projectid}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    setloader(true);
    axios(config)
      .then((response) => {
        setloader(false);
        console.log(response, "responseAllQuestion");
        if (response?.data?.status) {
          setQuestionsdata(response?.data?.data);
          console.log(response?.data?.data,'EditQuestiop nlof');
        }
      })
      .catch(function (error) {
        setloader(false);
        console.log(error.ERR_NETWORK, "error");
      });
  };
  useEffect(() => {
    getAlldata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateClick = () => {
    console.log(Id);
    const formdata = new FormData();
    formdata.append("question_id", Id);
    formdata.append("question", editedValue);
    console.log(formdata);
    var config = {
      method: "put",
      url: `${BaseUrl.baseurl}question/edit-question/`,
      data: formdata,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    setloader(true);
    axios(config)
      .then((response) => {
        setloader(false);
        console.log(response, "updateData");
        if (response?.data?.status) {
          toast.success(response?.data?.message);
          getAlldata();
          setEditedValue("");
          // Reset the questionValue state after updating
          setQuestionValue({});
        }
      })
      .catch(function (error) {
        setloader(false);
        console.log(error.ERR_NETWORK, "error");
      });
  };

//   const handleDeleteClick = (id) => {
//     var config = {
//       method: "delete",
//       url: `${BaseUrl.baseurl}question/delete-question/?id=${id}`,
//       headers: {
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     };
//     setloader(true);
//     axios(config)
//       .then((response) => {
//         setloader(false);
//         if (response.status) {
//           toast.success("Your data has been delete");
//           getAlldata();
//         }
//         console.log(response, "dataDelete");
//         // if (response?.data?.status) {
//         //   setQuestionsdata(response?.data?.data);
//         // }
//       })
//       .catch(function (error) {
//         setloader(false);
//         console.log(error.ERR_NETWORK, "error");
//       });
//   };

   const updateQuestionValue = (id , value , index) => {
    setEditedValue(value);
    setId(id);
   }

  return (
    <>
  
      <Toaster position="top-right" reverseOrder={false} />
      {loader ? (
        <div
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center  w-100"
        >
          <Rings
            visible={true}
            height="100"
            width="100"
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
            color="black"
          />
        </div>
      ) : (
        <section className="section sectionqtop" style={{ marginTop: "3%" }}>
          <div className="container">
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Update Question
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div>
                      <div className="mb-3">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          Question:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="recipient-name"
                          value={
                            editedValue
                              ? editedValue
                              : "" + questionValue?.question
                          }
                          onChange={(e) => setEditedValue(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      onClick={handleUpdateClick}
                      type="button"
                      className="btn btn-dark fw-bold rounded-pill px-3"
                      // data-bs-dismiss="modal"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div
                className="col-lg-10 bg-white col-md-8 p-3"
                style={{
                  border: "1px solid rgba(232, 157, 166, 1)",
                  height: "auto",
                  boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.438)'
                }}
              >
                {/* <div className=" d-flex justify-content-end">
                  <button
                    onClick={() => navigate("/createquestion")}
                    className="btn btn-dark fw-bold rounded-pill px-3 fw-bold"
                  >
                    Add +
                  </button>
                </div> */}
                {/* <div className="fw-bold text-center">Questions</div> */}
                <h2 className="fw-bold my-3 text-center">Questions</h2>
                <div className="form-card p-2">
                  <form className="login-form common-input-form">
                    <div className="form-body mt-3 mb-3">
                      <div className="row">
                        <div className="col-lg-12">
                          {questionsdata?.map((val , index) => (
                            <div
                              className="d-flex align-items-center border rounded my-2 "
                              key={val.id}
                            >
                              <input
                                style={{ backgroundColor: "#fff" }}
                                name="username"
                                id="username"
                                type="text"
                                defaultValue={val.question}
                                onChange={(e) => updateQuestionValue(val.id, e.target.value , index)}
                                className="form-control logininput border-0"
                              />
                              {/* <div className="d-flex gap-3">
                                <div
                                  type="button"
                                  className="mx-2 cursor-pointer"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  data-bs-whatever="@mdo"
                                  onClick={() => setQuestionValue(val)}
                                >
                                  <FaEdit />
                                </div>
                                <div
                                  className="cursor-pointer mx-2"
                                  onClick={() => handleDeleteClick(val.id)}
                                >
                                  <MdDeleteForever size={"20px"} />
                                </div>
                              </div> */}
                            </div>
                            
                          ))}
                          <div className="d-flex justify-content-center"> <button
                onClick={handleUpdateClick}
                className={`btn btn-dark rounded-pill py-2 m-2 px-4 fw-bold w-25 shadow`}
              >
                Update
              </button></div>

                          {/* <div className="row mt-2">
                            <div className="col-lg-12 text-center mt-3">
                              <button
                                style={{ height: "50px" }}
                                className="btn login-btn text-white w-50 loginsb2"
                                onClick={() => navigate("/projects")}
                              >
                                Submit
                              </button>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default GetAllQuestion;
