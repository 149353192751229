import React, { useState } from "react";
import Header from "../layout/header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseUrl from "./BaseUrl";
import Swal from "sweetalert2";
import { Rings } from "react-loader-spinner";

export default function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const projectid = location?.state;
  console.log(projectid, "projecgid");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const handleEmailChange = (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      const data = new FormData();
      data.append("newpassword", password);
      data.append("id", projectid);
      setLoader(true);
      var config = {
        method: "POST",
        url: `${BaseUrl.baseurlAuth}resetPassword/`,
        data: data,
        headers: {
          Accept: "application/json",
          // Authorization: `Bearer ${token}`,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response, "api");
          setLoader(false);
          if (response?.data?.status === true) {
            Swal.fire({
              text: response?.data?.message,
              icon: "success",
              button: "Ok",
            });
            navigate("/login");
          } else {
          }
        })
        .catch(function (error) {
          console.log(error.ERR_NETWORK, "error");
          setLoader(false);
          Swal.fire({
            text: error?.response?.data?.message,
            icon: "danger",
            confirmButtonText: "Ok",
          });
        });
    } else {
      alert("password and confirm password are not same");
    }
  };

  return (
    <div>
      <Header />
      {/* Start Login */}
      {loader ? (
        <div
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center  w-100"
        >
          <Rings
            visible={true}
            height="100"
            width="100"
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
            color="black"
          />
        </div>
      ) : (
        <section className="section loginsection">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8">
                <div className="form-card  p-2 ">
                  <form className="login-form common-input-form">
                    <div className="title-section">
                      <h2 className="title text-left loginhd">
                        Reset Your Password
                      </h2>
                      {/* <p className="grey-text font-weight-regular f-16">
                  Sign in to your account to continue.
                </p> */}
                    </div>
                    <div className="form-body mt-3 mb-3">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="pb-1 grey-text loginlbl">
                              Enter Password
                            </label>
                            <div className="position-relative">
                              {/* <i className="login-icon mdi mdi-account green-color" /> */}
                              <input
                                name="Otp"
                                id="Otp"
                                type="password"
                                className="form-control logininput"
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="pb-1 grey-text loginlbl">
                              Confirm Password
                            </label>
                            <div className="position-relative">
                              {/* <i className="login-icon mdi mdi-account green-color" /> */}
                              <input
                                name="Otp"
                                id="Otp"
                                type="password"
                                className="form-control logininput"
                                placeholder="Password"
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 text-center ">
                          <Link
                            style={{ height: "50px" }}
                            type="button"
                            className={` btn login-btn text-white w-100  loginsb ${
                              password ? "" : "disabled"
                            }`}
                            onClick={handleEmailChange}
                            disabled={!password}
                          >
                            {" "}
                            Reset Password{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* End Login */}
    </div>
  );
}
