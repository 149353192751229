import React, { useEffect, useState } from "react";
import Header from "../../layout/header";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseUrl from "../../auth/BaseUrl";
import { Rings } from "react-loader-spinner";
import toast, { Toaster } from "react-hot-toast";
import Participant from "../Participant";
import GetAllQuestion from "./getAllQuestions";
import Swal from "sweetalert2";

export default function EditProject() {
  const token = localStorage.getItem("accessToken");
  const projectid = localStorage.getItem("projectid");
  const location = useLocation();
  const { id } = location.state;
  console.log(id, "iddata11");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [fileError, setFileError] = useState("");

  const navigate = useNavigate();
  console.log(description, name, "description, name");
  const handleSubmit = (e) => {
    e.preventDefault();

    // Reset errors
    setNameError("");
    setDescriptionError("");
    setFileError("");
    if (name.length === 0) {
      setNameError("Please enter a project name.");
    } else if (description.length === 0) {
      setDescriptionError("Please enter a project brief.");
    } else if (!selectedFile) {
      setFileError("Please select jpg/png file.");
    } else {
      setLoader(true);
      const data = new FormData();
      data.append("id", id);
      data.append("name", name);
      data.append("description", description);
      data.append("image", selectedFile);

      var config = {
        method: "PUT",
        url: `${BaseUrl.baseurl}project/edit-project/`,
        data: data,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response, "response22");
          setLoader(false);
          if (response?.data?.status) {
            // Swal.fire({
            //   text: response?.data?.message,
            //   icon: "success",
            //   button: "Ok",
            // });
            localStorage.setItem("projectid", response?.data?.project_id);
            setTimeout(() => {
              toast.success(response?.data?.message);
            }, 500);
            navigate("/projects");
            // setTimeout(()=>{
            //   navigate("/createquestion");
            // }, 1500)
          }
        })
        .catch(function (error) {
          setLoader(false);
          setTimeout(() => {
            toast.success(error?.data?.message);
          }, 500);
          console.log(error.ERR_NETWORK, "error");
        });
    }
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   setSelectedFile(file);
  // };

  const handleFileChange = (event) => {
    const allowedFileTypes = ["image/jpeg", "image/png"];
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (allowedFileTypes.includes(selectedFile.type)) {
        setSelectedFile(selectedFile);
        setFileError("");
      } else {
        setSelectedFile(null);
        // Invalid file type, show error message
        setFileError("Only JPG and PNG files are allowed.");
      }
    }
  };

  const dataproject = async () => {
    var config = {
      method: "get",
      url: `${BaseUrl.baseurl}/project/get-specific-project/?id=${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response) => {
        const dsfads = response.data.data[0];
        setName(dsfads.name);
        setDescription(dsfads.description);
        console.log(response.data.data[0], "responseAllInvitation11");

        if (response?.status) {
          //   setInvitedata(response?.data?.user_invitations_email);
        }
      })
      .catch(function (error) {
        console.log(error.ERR_NETWORK, "error");
      });
  };
  useEffect(() => {
    dataproject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteClick = async () => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        html: "Are you sure you want to delete?",
        showDenyButton: true,
        denyButtonColor:"#ff0000",
        confirmButtonText: "Yes",
        confirmButtonColor: 'black',
      });
  
      if (result.isConfirmed) {
        const config = {
          method: "delete",
          url: `${BaseUrl.baseurl}project/delete-project/?id=${projectid}`,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
  
        const response = await axios(config);
  
        if (response.status) {
          setTimeout(() => {
            toast.success("Your project has been deleted");
          }, 1000);
          navigate("/projects");
        } else {
          console.log(response.data); // Log error response
        }
      }
    } catch (error) {
      console.log(error); // Log any other errors
      toast.error("An error occurred while deleting the project");
    }
  };
  
  


  return (
    <div>
      <Header />
      <Toaster position="top-right" reverseOrder={false} />
      {loader ? (
        <div
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center  w-100"
        >
          <Rings
            visible={true}
            height="100"
            width="100"
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
            color="black"
          />
        </div>
      ) : (
        <>
          <section className="section projectinfo">
            <div className="container">
              <div className="row justify-content-center">
                <div
                  className="col-lg-10 bg-white col-md-8 p-3"
                  style={{ border: "1px solid rgba(232, 157, 166, 1)",  boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.438)'}}
                >
                  <div className="col-md-12">
                    <div className="text-center">
                      <span className="grey-text font-weight-regular projectinfosub ">
                        {" "}
                        Elicitation Session{" "}
                      </span>
                    </div>
                  </div>
                  <h2 className="title  projectinfohd d-flex justify-content-center">
                    Project Info.
                  </h2>

                  <div className="form-card  p-2 ">
                    <form className="login-form common-input-form">
                      <div className="form-body">
                        <div className="row gap-2">
                          <div className="col-lg-12">
                            <div className="form-group">
                              <div className="position-relative">
                                <input
                                  style={{ backgroundColor: "#fff" }}
                                  name="username"
                                  id="username"
                                  type="text"
                                  className="form-control logininput"
                                  placeholder="Project Name"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                />
                                {/* Display error message */}
                                {nameError && (
                                  <span className="error-message text-danger">
                                    {nameError}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="form-group">
                              <div className="position-relative">
                                <textarea
                                  style={{ backgroundColor: "#fff" }}
                                  name="username"
                                  id="username"
                                  type="text"
                                  className="form-control logininput"
                                  placeholder="Project Brief..."
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                />
                                {/* Display error message */}
                                {descriptionError && (
                                  <span className="error-message text-danger">
                                    {descriptionError}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <div className="position-relative">
                                <input
                                  style={{ backgroundColor: "#fff" }}
                                  name="file"
                                  id="file"
                                  type="file"
                                  className="form-control logininput p-3"
                                  // placeholder="Project Name"
                                  onChange={handleFileChange}
                                />
                                {/* Display error message */}
                                {fileError ? (
                                  <span className="error-message text-danger">
                                    {fileError}
                                  </span>
                                ) : (
                                  <span className="ms-2 fw-bold text-dark fs-6">
                                    jpg and png file allowed
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <GetAllQuestion />
          <Participant />
          <div className="d-flex flex-column justify-content-center align-items-center my-3">
          <button
                onClick={handleSubmit}
                className={`btn btn-dark rounded-pill py-2 m-2 px-4 fw-bold w-25 shadow`}
              >
                Update
              </button>
          <button
                onClick={() => handleDeleteClick()}
                className={`btn btn-dark rounded-pill py-2 m-2 px-4 fw-bold w-25 shadow`}
              >
                Delete
              </button>
          </div>
        </>
      )}
    </div>
  );
}
