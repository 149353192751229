// reducers.js
import { createSlice } from "@reduxjs/toolkit";

const counterSlice = createSlice({
  name: "counter",
  initialState: 0,
  reducers: {
    increment: (state) => {
        console.log('stateArrar:', state)
      return 
    },
  },
});
export const { increment } = counterSlice.actions;

export default counterSlice.reducer;
