import React, { useState } from "react";
import Header from "../layout/header";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseUrl from "./BaseUrl";
import Swal from "sweetalert2";
import { Rings } from "react-loader-spinner";

export default function Forget() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);

  const handleEmailChange = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("email", email);
    setLoader(true);
    var config = {
      method: "POST",
      url: `${BaseUrl.baseurlAuth}forgetPassSendMail/`,
      data: data,
      headers: {
        Accept: "application/json",
        // Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response, "api");
        setLoader(false);
        if (response?.data?.status === true) {
          Swal.fire({
            text: response?.data?.message,
            icon: "success",
            button: "Ok",
          });
          navigate("/varify", { state: response?.data?.id });
        } else {
        }
      })
      .catch(function (error) {
        console.log(error.ERR_NETWORK, "error");
        setLoader(false);
        Swal.fire({
          text: error?.response?.data?.message,
          icon: "danger",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <div>
      <Header />
      {loader ? (
        <div
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center  w-100"
        >
          <Rings
            visible={true}
            height="100"
            width="100"
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
            color="black"
          />
        </div>
      ) : (
        <section className="section loginsection">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8">
                <div className="form-card  p-2 ">
                  <form className="login-form common-input-form">
                    <div className="title-section">
                      <h2 className="title text-left loginhd">
                        Forget Password
                      </h2>
                    </div>
                    <div className="form-body mt-3 mb-3">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="pb-1 grey-text loginlbl">
                              Email address
                            </label>
                            <div className="position-relative">
                              {/* <i className="login-icon mdi mdi-account green-color" /> */}
                              <input
                                name="email"
                                id="email"
                                type="email"
                                className="form-control logininput"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 text-center ">
                          <Link
                            style={{ height: "50px" }}
                            type="button"
                            className={` btn login-btn text-white w-100  loginsb ${
                              email ? "" : "disabled"
                            }`}
                            onClick={handleEmailChange}
                            disabled={!email}
                          >
                            {" "}
                            Send Email{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* End Login */}
    </div>
  );
}
