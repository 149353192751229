import React, { useEffect, useState } from "react";
import BaseUrl from "../auth/BaseUrl";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { Rings } from "react-loader-spinner";
import toast, {  } from "react-hot-toast";

const Table = () => {
  const token = localStorage.getItem("accessToken");
  const project_id = localStorage.getItem("projectid");
  const [loader, setloader] = useState(false);
  const [allCreateProjects, setAllCreateProjects] = useState([]);

  const [fullname, Setfullname] = useState("");
  const [id, Setid] = useState("");
  const [contact, ] = useState("");
  const [date_of_birth, Set_date_of_birth] = useState();
  const [gender, Setgender] = useState("");

  const getProjects = () => {
 

    setloader(true);
    var config = {
      method: "get",
      url: `${BaseUrl.baseurl}Invite/filter_more_details/?project_id=${project_id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response, "responsedata313");
        setloader(false);
        if (response?.status) {
          console.log(response);
          setAllCreateProjects(response?.data?.user_invitations);
        }
      })
      .catch(function (error) {
        console.log(error.ERR_NETWORK, "error");
        setloader(false);
      });
  };

  console.log(fullname);
  // const changeDate = (date) => {
  //   const newDate = new Date(date);
  //   Set_date_of_birth(newDate);
  // };
  const UpdateProfileHandler = () => {
    setloader(true);
    const data = new FormData();
    data.append("id", id);
    data.append("fullname", fullname);
    data.append("contact", contact);
    data.append("gender", gender);
    data.append("date_of_birth", date_of_birth);
    var config = {
      method: "put",
      url: `${BaseUrl.baseurl}Invite/update_invitations/`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        console.log(response, "update");
        setloader(false);
        if (response?.status) {
          console.log(response);
          var config = {
            method: "get",
            url: `${BaseUrl.baseurl}Invite/filter_more_details/?project_id=${project_id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          axios(config)
            .then((response) => {
              console.log(response, "responsedata313");
              setloader(false);
              if (response?.status) {
                console.log(response);
                setAllCreateProjects(response?.data?.user_invitations);
              }
            })
            .catch(function (error) {
              console.log(error.ERR_NETWORK, "error");
              setloader(false);
            });
          setTimeout(() => {
            toast.success(response?.data?.message);
          }, 500);
          // UpdateValue(response);
        }
      })
      .catch(function (error) {
        console.log(error.ERR_NETWORK, "error");
        setloader(false);
      });
  };

  // const getProjectsall = () => {
  //   setloader(true);
  //   var config = {
  //     method: "get",
  //     url: `${BaseUrl.baseurl}project/get-project/`,
  //     headers: {
  //       Accept: "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };

  //   axios(config)
  //     .then((response) => {
  //       setloader(false);
  //       if (response?.data?.status) {
  //         setAllCreateProjects(response?.data?.data);
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error.ERR_NETWORK, "error");
  //       setloader(false);
  //     });
  // };

  useEffect(() => {
    getProjects();
    // getProjectsall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(allCreateProjects, "allCreateProjects");
  return (
    <>
      {loader ? (
        <div
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center  w-100"
        >
          <Rings
            visible={true}
            height="100"
            width="100"
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
            color="black"
          />
        </div>
      ) : (
        <div class="table-responsive">
          <div
            className="modal fade"
            id="exampleModal12"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <form>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Update Participant
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div>
                      <div className="my-1">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label fw-bold"
                        >
                          Name:
                        </label>
                        <input
                          required
                          value={fullname}
                          onChange={(e) => Setfullname(e.target.value)}
                          type="text"
                          className="form-control"
                          id="recipient-name"
                        />
                      </div>
                      {/* <div className="my-1">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label fw-bold"
                      >
                        Contact:
                      </label>
                      <input
                        value={contact}
                        onChange={(e) => Setcontact(e.target.value)}
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div> */}
                      <div className="my-1">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label fw-bold"
                        >
                          Gender:
                        </label>
                        <input
                          required
                          value={gender}
                          onChange={(e) => Setgender(e.target.value)}
                          type="text"
                          className="form-control"
                          id="recipient-name"
                        />
                      </div>
                      <div className="my-1">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label fw-bold"
                        >
                          Date:
                        </label>
                        <input
                          required
                          type="date"
                          value={date_of_birth}
                          onChange={(e) => Set_date_of_birth(e.target.value)}
                          className="form-control"
                          id="recipient-name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      onClick={UpdateProfileHandler}
                      type="button"
                      className="btn btn-dark fw-bold rounded-pill px-5"
                      data-bs-dismiss="modal"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {allCreateProjects.length === 0 ? (
            <div className="text-center mt-3">Participant: 0</div>
          ) : (
            <table class="table align-middle">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allCreateProjects?.map((val, index) => {
                  return (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{val.full_name}</td>
                      <td>{val.gender}</td>
                      <td>{val.date_of_birth}</td>
                      <td className="">
                        <div
                          type="button"
                          className=" px-4 py-1 m-0"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal12"
                          data-bs-whatever="@mdo"
                          onClick={() => {
                            Setfullname(val.full_name);
                            Set_date_of_birth(val.date_of_birth);
                            Setgender(val.gender);
                            Setid(val.id);
                            console.log(val.date_of_birth, "date");
                          }}
                        >
                          {" "}
                          <FaEdit />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      )}
    </>
  );
};

export default Table;
