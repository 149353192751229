import React, { useEffect, useState } from "react";
// import { Rings } from "react-loader-spinner";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Header from "../layout/header";
import BaseUrl from "../auth/BaseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const GraphFeedback = () => {
  const token = localStorage.getItem("accessToken");
  const project = JSON.parse(localStorage.getItem("dataId"));
  const dataId = project.id;

  const navigate = useNavigate();
  const [keys, setKeys] = useState([]);
  const [values, setValues] = useState([]);
  // const location = useLocation();
  // const dataID = location.state;

  // console.log(dataID, "dataID");
  useEffect(() => {
    var config = {
      method: "get",
      // url: `${BaseUrl.baseurl}Feedback/graph/?project_id=5e4c7e6a-37d6-4e00-9780-3c87ac200d71`,
      url: `${BaseUrl.baseurl}Feedback/graph/?project_id=${dataId}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // setloader(true);
    axios(config)
      .then((response) => {
        // setloader(false);
        if (response?.data?.status) {
          console.log(response?.data, "responseGraph");
          setKeys(response?.data?.keys);
          setValues(response?.data?.combined_values);
        }
      })
      .catch(function (error) {
        console.log(error.ERR_NETWORK, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(keys, "keys");
  console.log(values, "values");

  const chartData = keys?.map((name, index) => ({
    name,
    value: values[index],
  }));

  console.log(chartData, "chartData");
  return (
    <>
      <div>
        <Header />

        <section className="section projectinfo">
          <div className="container">
            <div className="row justify-content-center">
              <div
                className="col-lg-12 bg-white col-md-8 p-3"
                style={{ border: "1px solid rgba(232, 157, 166, 1)" }}
              >
                 <div className="d-flex justify-content-start mb-4">
                        <button
                          onClick={() => navigate("/projectdetails")}
                          className="btn btn-dark rounded-pill"
                          style={{ fontWeight: "bold", color: "white", padding:'5px 30px' }}
                        >
                          Back
                        </button>
                      </div>
                <div className="form-card  p-2 ">
                  <form className="login-form common-input-form">
                    <div className="form-body">
                      {/* <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="position-relative">
                              <input
                                style={{ backgroundColor: "#fff" }}
                                name="username"
                                id="username"
                                type="text"
                                value={questionData}
                                className="form-control logininput mt-2"
                              />
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div style={{ height: "400px", overflowY: "auto" }}>
                        <ResponsiveContainer className="responsive-container" height={300}>
                          <BarChart data={chartData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="value" barSize={50} fill="black" />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                     
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <style>
        {`
        .row.cstm {
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;
            align-content: center;
            align-items: baseline;
            justify-content: center;
        }
        `}
      </style>
    </>
  );
};

export default GraphFeedback;
