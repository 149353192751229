import React from "react";
import Table from "./Table";

const Participant = () => {
  return (
    <div className="container-12">
       <div className="cutome-table">
        <div className="bg-white w-100 box-padding h-100">
          <h2 className="fw-bold mt-3 mb-4 text-center">Participants</h2>
          <div className="w-100">
            <Table />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Participant;
