import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const ProtectRoute = (props) => {
    const navigate = useNavigate()
    const { Component } = props;
    useEffect(() => {
        const token = localStorage.getItem("accessToken");
        if (!token) {
            navigate('/')
        }
    })

    return (
        <Component />
    )
}

export default ProtectRoute;